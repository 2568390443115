'use strict';

// import Vuex from 'vuex';
import Vue from 'vue';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import axios from 'axios';

import deepmerge from 'deepmerge';
import cookies from 'js-cookie';

// import log from '@dengl/ota/utils/log';

const forumCategories = [
	{
		category: 'destination',
		slugs: { nl: 'skigebieden' }
	},
	{
		category: 'travelogues',
		slugs: { nl: 'reisverslagen' }
	},
	{
		category: 'weather_snow',
		slugs: { nl: 'weer-en-sneeuw' }
	},
	{
		category: 'favorites',
		slugs: { nl: 'favorieten' }
	},
	{
		category: 'gear',
		slugs: { nl: 'materiaal-en-techniek' }
	},
	{
		category: 'travel',
		slugs: { nl: 'reizen' }
	},
	{
		category: 'marketplace',
		slugs: { nl: 'marktplaats' }
	},
	{
		category: 'photo_video',
		slugs: { nl: 'fotos-en-filmpjes' }
	},
	{
		category: 'meet_greet',
		slugs: { nl: 'meet-en-greet' }
	},
	{
		category: 'work',
		slugs: { nl: 'werken-in-de-sneeuw' }
	},
	{
		category: 'stube',
		slugs: { nl: 'stube' }
	}
];

const defaultAds = {
	sidebar: {
		visible: false,
		targets: {},
		type: null
	},
	navigation: {
		visible: false,
		target: {},
		type: null
	}
};

const query = gql`
	query getGlobalData {
		notifications {
			id
			title
			url
			type
			isMailSent
		}
		countries {
			id
			name
			slug
			countryCode
		}
	}
`;

const userQuery = gql`
	query getUserData {
		me {
			id
			email
			# role
			isAdmin
			notificationCount
			notifications {
				id
				type
				createdAt
				item {
					... on Conversation {
						id
						title
						participants {
							id
							username
						}
					}
				}
			}
			profile {
				username
				slug
				avatar
				isAllowedToPost
				forecastDestination {
					id
					date
					type
				}
			}
			memberships {
				id
				external_id
				label
				... on DifferMembership {
					isVerified
				}
			}
		}
	}
`;

const localeDomains = {
	'de-DE': process.env.DOMAIN_DE,
	'nl-NL': process.env.DOMAIN_NL,
	'nl-BE': process.env.DOMAIN_BE
};

const createStore = {
	// return new Vuex.Store({
	getters: {
		preferedCity: state => {
			return (
				{
					'nl-NL': 'utrecht',
					'nl-BE': 'brussel',
					'de-DE': 'berlin'
				}[state.i18n.locale] || 'utrecht'
			);
		},
		forumNotifications: state => {
			return state.notifications.filter(notification => {
				return ['mention', 'favorite'].includes(notification.type);
			});
		},
		inboxNotifications: state => {
			return state.inboxNotifications || [];
			// return state.notifications.filter(notification => {
			// 	return ['privatemessage'].includes(notification.type);
			// });
		},
		forumLastVisit: _state => {
			if (process.browser) {
				return cookies.get('lastForumVisit');
			}
		},
		isAllowedToPost: state => {
			return state.user.profile && state.user.profile.isAllowedToPost;
		},
		isMembershipVerified: state => {
			return state.memberships.some(membership => membership.isVerified);
		},
		membershipNumber: state => {
			const firstValidMembership = state.memberships.find(
				membership => membership.isVerified && membership.external_id
			);
			return firstValidMembership.external_id;
		},
		membershipType: state => {
			const membershipTypes = state.memberships.map(
				membership => membership.label
			);

			if (membershipTypes.includes('nskiv')) {
				return 'nskiv';
			}
			if (membershipTypes.includes('club')) {
				return 'club';
			}
			if (state.user.id) {
				return 'community';
			}
			return false;
		}
	},
	state: () => ({
		forumCategories,
		user: {},
		showFullMenu: false,
		localeDomains,
		countries: [],
		notifications: [],
		inboxNotifications: [],
		forecastDisplayDate: '',
		rundate: '',
		runhour: '',
		weathermapsDomain: '',
		title: 'wintersport',
		meta: {
			title: 'Wintersport begint op wintersport.nl',
			description:
				'Onafhankelijke informatie over wintersport, skigebieden en bestemmingen. De beste voorbereiding op een wintersportvakantie start hier.',
			hasFollow: true,
			hasIndex: true,
			image:
				'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg'
		},
		ads: {
			...defaultAds
		},
		breadcrumbs: [],
		showAllWebcamCountries: false,
		snowheights: {},
		areas: {},
		content_width: {
			full: 1200,
			infosheet: 800
		},
		forecastDestination: {
			id: 'ed5a3dfc-017c-5ae6-9239-484701082866',
			type: 'Area'
		},
		dntConsent: {},
		jsonld: {},
		showNavigationDrawer: false,
		accohash: '',
		memberships: []
	}),
	mutations: {
		TOGGLE_FULLMENU(state) {
			state.showFullMenu = !state.showFullMenu;
		},
		HIDE_FULLMENU(state) {
			state.showFullMenu = false;
		},
		SET_USER(state, user) {
			Vue.set(state, 'user', user || {});
		},
		SET_FORUM_LAST_VISIT(_state, _data) {
			const lastForumVisitCookie = cookies.get('lastForumVisit');
			if (!lastForumVisitCookie)
				cookies.set(
					'lastForumVisit',
					localStorage.getItem('lastForumVisit')
						? localStorage.getItem('lastForumVisit')
						: dayjs().format()
				);

			localStorage.setItem('lastForumVisit', dayjs().format());
		},
		SET_META(state, meta) {
			Vue.set(state, 'meta', meta);
		},
		SET_ADS(state, ads) {
			Vue.set(state, 'ads', deepmerge(defaultAds, ads));
		},
		SET_COUNTRIES(state, countries) {
			Vue.set(state, 'countries', countries);
		},
		SET_NOTIFICATIONS(state, notifications) {
			Vue.set(state, 'notifications', notifications);
		},
		SET_INBOXNOTIFICATIONS(state, notifications) {
			Vue.set(state, 'inboxNotifications', notifications);
		},
		SET_RUNDATE(state, rundate) {
			Vue.set(state, 'rundate', rundate);
		},
		SET_RUNHOUR(state, runhour) {
			Vue.set(state, 'runhour', runhour);
		},
		SET_FORECAST_DISPLAY_DATE(state, date) {
			Vue.set(
				state,
				'forecastDisplayDate',
				dayjs(date).add(1, 'day').format('YYYY-MM-DD')
			);
		},
		SET_WEATHERMAPS_DOMAIN(state, domain) {
			Vue.set(state, 'weathermapsDomain', domain);
		},
		SET_AREAS(state, areas) {
			Vue.set(state, 'areas', areas);
		},
		SET_BREADCRUMBS(state, breadcrumbs) {
			Vue.set(state, 'breadcrumbs', breadcrumbs || []);
		},
		SET_JSONLD(state, { type, data }) {
			Vue.set(state.jsonld, type, data);
		},
		SET_CONTENT_WIDTH(state, width) {
			Vue.set(state, 'content_width', width);
		},
		setShowAllWebcamCountries(state, value) {
			Vue.set(state, 'showAllWebcamCountries', value);
		},
		SET_FORECAST_DESTINATION(state, value) {
			Vue.set(state, 'forecastDestination', value);
		},
		SET_DNT_CONSENT(state, value) {
			Vue.set(state, 'dntConsent', deepmerge(state.dntConsent, value));
		},
		SET_SHOW_NAVIGATION_DRAWER(state, value) {
			Vue.set(state, 'showNavigationDrawer', value);
		},
		SET_ACCOHASH(state, hash) {
			Vue.set(state, 'accohash', hash);
		},
		SET_MEMBERSHIPS(state, memberships) {
			Vue.set(state, 'memberships', memberships);
		}
	},
	actions: {
		async nuxtServerInit({ commit }, { app, req, redirect }) {
			const client = app.$graphClient;

			const currentRun18 = await axios.get(
				`${process.env.WEATHERMAPS_DOMAIN}/alps/current_run_18.json`
			);

			commit('SET_RUNDATE', currentRun18.data.rundate);
			commit('SET_RUNHOUR', currentRun18.data.rundir);
			commit('SET_FORECAST_DISPLAY_DATE', currentRun18.data.rundate);
			commit('SET_WEATHERMAPS_DOMAIN', process.env.WEATHERMAPS_DOMAIN);

			const hasCookie =
				req.headers.cookie && req.headers.cookie.includes('apollo-token');

			const userQueryPromise = hasCookie
				? client
					.query({
						query: userQuery
					})
					.then(({ data }) => {
						commit('SET_USER', { ...data.me, ...data.me.profile });
						commit('SET_MEMBERSHIPS', data.me.memberships);
						commit('SET_INBOXNOTIFICATIONS', data.me.notifications);

						if (data.me.profile.forecastDestination) {
							commit(
								'SET_FORECAST_DESTINATION',
								data.me.profile.forecastDestination
							);
						}

						// if user is loggedin, but has no profile, redirect to username page
						if (!data.me.profile && req.url !== '/createprofile') {
							redirect('/createprofile');
						}
					})
					.catch(_error => {
						// do nothing
					})
				: null;

			return Promise.all(
				[
					client
						.query({
							query
						})
						.then(({ data }) => {
							commit('SET_COUNTRIES', data.countries);
						}),
					userQueryPromise
				].filter(Boolean)
			);
		},
		fetchNotifications({ commit }) {
			return this.app.$graphClient
				.query({
					fetchPolicy: 'no-cache',
					query: gql`
						query notifications {
							notifications {
								id
								title
								url
								type
								isMailSent
							}
						}
					`
				})
				.then(({ data }) => {
					commit('SET_NOTIFICATIONS', data.notifications);
				});
		},
		fetchInboxNotifications({ commit }) {
			return this.app.$graphClient
				.query({
					fetchPolicy: 'no-cache',
					query: gql`
						query inboxNotifications {
							me {
								id
								notificationCount
								notifications {
									id
									type
									createdAt
									item {
										... on Conversation {
											id
											title
											participants {
												id
												username
											}
										}
									}
								}
							}
						}
					`
				})
				.then(({ data }) => {
					commit('SET_INBOXNOTIFICATIONS', data.me.notifications);
				});
		},
		markNotificationAsRead({ commit }, ids) {
			return this.app.$graphClient
				.mutate({
					mutation: gql`
						mutation clearNotifications($ids: [ID!]!) {
							notifications: clearNotifications(ids: $ids) {
								id
								title
								url
								type
								isMailSent
							}
						}
					`,
					variables: {
						ids
					}
				})
				.then(({ data }) => {
					commit('SET_NOTIFICATIONS', data.notifications);
				});
		},
		checkSession({ commit }) {
			return this.app.$graphClient
				.query({
					fetchPolicy: 'no-cache',
					query: userQuery
				})
				.then(({ data }) => {
					if (data.me) {
						commit('SET_USER', { ...data.me, ...data.me.profile });
						commit('SET_MEMBERSHIPS', data.me.memberships);
					}
				})
				.catch(_error => {
					// console.log(error);
				});
		}
	},
	strict: true
};

export default createStore;
